import React from "react";
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";
import Message from "./Message";
import useFetch from "./UseFetch";

const firebaseConfig = {
  apiKey: "AIzaSyDJX2Y9D6dHJKf1KHdWVRFLVic39dby0Yg",
  authDomain: "chat-16916.firebaseapp.com",
  databaseURL: "https://chat-16916.firebaseio.com",
  projectId: "chat-16916",
  storageBucket: "chat-16916.appspot.com",
  messagingSenderId: "52872795649",
  appId: "1:52872795649:web:44b5d9124e990837229200",
  measurementId: "G-X03VLPG3G9"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const Chat = () => {
  const { username, setUsername, message, setMessage, allPosts } = useFetch();

  function createPost(username, message, date) {
    if (username && message) {
      const postData = {
        author: username,
        message: message,
        date: date
      };
      var newPostKey = firebase
        .database()
        .ref()
        .child("posts")
        .push().key;

      var updates = {};
      updates["/posts/" + newPostKey] = postData;

      return firebase
        .database()
        .ref()
        .update(updates);
    }
  }

  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    createPost(username, message, formatDate(new Date()));
  }

  const allChat = allPosts.map((data, key) => {
    return <Message key={key} post={data} />;
  });

  return (
    <div>
      <div className="header">
        <h1>React Chat</h1>
      </div>
      <div className="form-container">
        <form id="form" onSubmit={e => handleSubmit(e)}>
          <input
            value={username}
            type="text"
            onChange={e => setUsername(e.target.value)}
            placeholder="Username"
            className="username-box"
          ></input>
          <div className="message-container">
            <input
              value={message}
              id="messageBox"
              type="text"
              className="message-box"
              onChange={e => setMessage(e.target.value)}
              placeholder="Type a message!"
            ></input>

            <input className="submit" type="submit" value="→"></input>
          </div>
        </form>
      </div>
      <div className="chat-container">{allChat}</div>
    </div>
  );
};

export default Chat;
