import React from "react";

const Message = ({ post }) => {
  return (
    <div className="post">
      <h3>{post.author}</h3>
      <p>{post.message}</p>
      <p className="time">{post.time}</p>
    </div>
  );
};

export default Message;
