import { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import "firebase/database";

const useFetch = () => {
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([
    {
      user: "",
      message: "",
      date: ""
    }
  ]);
  const dbOBJ = firebase.database().ref("posts");

  useEffect(() => {
    async function fetchData() {
      dbOBJ.on("child_added", function(snapshot) {
        console.log("Fetch Data", snapshot.val());
        setPosts([
          {
            author: snapshot.val().author,
            message: snapshot.val().message,
            time: snapshot.val().date
          }
        ]);
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    setAllPosts(p => [...p, ...posts]);
  }, [posts]);

  return { username, setUsername, message, setMessage, posts, allPosts };
};

export default useFetch;
